import React from "react";
import "./Home.scss";

import logo from "./../../assets/logo.png";

import header from "./../../assets/images/home/header.png";

import aboutUs1 from "../../assets/images/home/nosotros1.png";
import aboutUs2 from "../../assets/images/home/nosotros2.png";
import service1 from "../../assets/images/services/servicio1.png";
import service3 from "../../assets/images/services/servicio3.png";

import { SectionQr } from "../../components/shared/SectionQr";
import { Clients } from "../../components/Clients";
import { SectionContacts } from "../../components/shared/SectionContacts";

export const Home = () => {
  const [visits, setVisits] = React.useState(0);

  const keySession = "sessionHitCounter";
  const apiGet = "https://website-features.wallpanadev.com/counter/getViews";
  const apiPost = "https://website-features.wallpanadev.com/counter/addView";
  const request = {
    method: "Post",
    headers: { "Content-type": "application/json;charset=UTF-8" },
    body: JSON.stringify({
      token:
        "U2FsdGVkX1+3/kpYdAQjhHuk6HuOG0pqjvNWzI2IBDhPWslg38MbMoww0Zy8MGZ+otaN0nyAXcnhSoZdvh2kMO+LpW4uOrp1/SwiuPxziOFKc/LkUKeHiqm52tGGT8Vz6NV8kMot63toT4PnDRjswgaUOO/K+Ut5k7Rw/cSqefN231c/Pj6vTtq9is3nzBYpTcB66x20p+HvczSJlSyXpXCkXkDtn/hF30opGZRKQy4=",
    }),
  };

  React.useEffect(() => {
    hitCounter();
  }, []);

  const hitCounter = async () => {
    const counter = sessionStorage.getItem(keySession);

    if (counter) {
      fetch(apiGet, request)
        .then((res) => res.json())
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          setVisits(response.value);
        });
    } else {
      sessionStorage.setItem(keySession, 0);
      fetch(apiPost, request)
        .then((res) => res.json())
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          sessionStorage.setItem(keySession, response.value);
          setVisits(response.value);
        });
    }
  };

  return (
    <div className="container-fluid">
      {/*-- Header --*/}
      <div className="row header-home">
        <div className="col">
          <img src={logo} alt="logo" />
          <img src={header} alt="header" />
          <h1>¡Tu tiempo en nuestras manos!</h1>
        </div>
      </div>
      {/*-- About us --*/}
      <div className="row py-5 bg-primary text-center">
        <div className="col">
          <div className="row mb-2">
            <div className="col">
              <h1 className="text-white">¿Quienes Somos?</h1>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 mx-auto">
              <p className="text-white fs-5">
                Somos una empresa 100% Ecuatoriana fundada en 2017, dedicada al alquiler de autos particulares y comerciales para el
                traslado de personas, mercaderías y documentos, en las ciudades de Quito e Ibarra (compartido) y servicio exclusivo
                a nivel nacional.
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <a className="btn btn-secondary btn-lg" href="/empresa">
                Conoce más
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-lg-7 col-md-6 mx-auto">
              <img src={aboutUs1} alt="aboutUs" width="100%" />
            </div>
          </div>
        </div>
      </div>
      {/* HitCounter */}
      <div className="row py-4 text-center text-primary">
        <div className="col-8 col-md-4 col-lg-2 p-3 bg-secondary mx-auto rounded">
          <i class="bi bi-eye-fill fs-3"></i>
          <p className="fs-5">Contador de visitas</p>
          <p className="counter">{visits}</p>
        </div>
      </div>
      {/*-- Tittle Offers --*/}
      <div className="row text-center pt-5 mb-4">
        <div className="col">
          <h1>¿Qué Ofrecemos?</h1>
        </div>
      </div>
      {/*-- Offers --*/}
      <div className="row mb-5 text-center">
        <div className="col-lg-3 col-8 bg-secondary rounded mx-auto d-flex py-3 mb-3">
          <h4 className="m-auto">PRECIOS ACCESIBLE</h4>
        </div>
        <div className="col-lg-4 col-8 bg-secondary rounded mx-auto d-flex py-3 mb-3">
          <h4 className="m-auto">INFORMACIÓN DEL CONDUCTOR</h4>
        </div>
        <div className="col-lg-3 col-8 bg-secondary rounded mx-auto d-flex py-3 mb-3">
          <h4 className="m-auto">SERVICIO EXPRÉS</h4>
        </div>
      </div>
      {/*-- Offers 1 --*/}
      <div className="row justify-content-center text-center mb-5">
        <div className="col-11 col-md-10 bg-primary">
          <div className="row px-4 py-5">
            <div className="col-lg-6 m-auto">
              <h2 className="text-center text-secondary">Nuestros Servicios</h2>
              <p className="mb-2 text-white">Proporcionamos servicios puntuales y de excelencia en:</p>
              <p className="m-0 text-white">
                Alquiler de autos particulares y comerciales para el traslado de personas en servicio económico y exclusivo,
                Paquetes y documentos siempre a tiempo, entre las ciudades Quito e Ibarra (compartido).
              </p>
            </div>
            <div className="col-md-8 col-lg-6 mt-4 mt-lg-0 mx-auto">
              <img src={aboutUs2} alt="aboutUs2" width="100%" />
            </div>
          </div>
        </div>
      </div>
      {/*-- Offers 2 --*/}
      <div className="row mb-5 justify-content-center text-center">
        <div className="col-11 col-md-10 bg-secondary">
          <div className="row py-5 justify-content-center">
            <div className="col-md-8 col-lg-6 align-self-center">
              <img src={service1} alt="service1" width="100%" />
            </div>
            <div className="col-lg-6">
              {/*-- Offers 2.1 --*/}
              <div className="row m-3 bg-primary py-4">
                <div className="col-md-2 ms-auto my-auto">
                  <i className="bi bi-car-front-fill fs-1 text-white"></i>
                </div>
                <div className="col-md-8 me-auto mt-3 mt-md-0">
                  <h5 className="text-white">ALQUILER DE AUTOS PARTICULARES</h5>
                  <hr className="text-white" />
                  <p className="m-0 text-white">Paga lo justo, paga lo que marca la app</p>
                </div>
              </div>
              {/*-- Offers 2.2 --*/}
              <div className="row m-3 bg-primary py-4">
                <div className="col-md-2 ms-auto my-auto">
                  <i className="bi bi-box-seam-fill fs-1 text-white"></i>
                </div>
                <div className="col-md-8 me-auto mt-3 mt-md-0">
                  <h5 className="text-white">SERVICIO DE ENCOMIENDAS</h5>
                  <hr className="text-white" />
                  <p className="m-0 text-white">Encomiendas de puerta a puerta</p>
                </div>
              </div>
              {/*-- Offers 2.3 --*/}
              <div className="row m-3 bg-primary py-4">
                <div className="col-md-2 ms-auto my-auto">
                  <i className="bi bi-credit-card-fill fs-1 text-white"></i>
                </div>
                <div className="col-md-8 me-auto mt-3 mt-md-0">
                  <h5 className="text-white">PAGA TUS VIAJES</h5>
                  <hr className="text-white" />
                  <p className="m-0 text-white">Con tu tarjeta de crédito preferida</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*-- Offers 3 --*/}
      <div className="row justify-content-center mb-5">
        <div className="col-11 col-md-10 bg-secondary">
          <div className="row align-items-center p-5">
            <div className="col-md-6 mx-auto mb-3 mb-md-0">
              <img src={service3} alt="service3" width="100%" />
            </div>
            <div className="col-md-6 text-center">
              <div className="row justify-content-center">
                <div className="col-md-10 rounded bg-primary position-relative py-4">
                  <a href="/servicios" className="text-decoration-none stretched-link">
                    <h3 className="text-white">Conoce todos nuestros servicios</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* QR */}
      <SectionQr bgColor={"bg-primary"} titleColor="text-secondary" textColor={"text-white"} />
      {/*-- Title Clients --*/}
      <div className="row pt-5 mb-5 text-center">
        <div className="col">
          <h1>Nuestros Clientes</h1>
        </div>
      </div>
      {/*-- Clients --*/}
      <Clients />
      <div className="mb-5"></div>
      {/*-- Contacts --*/}
      <SectionContacts bgColor={"bg-secondary"} />
    </div>
  );
};
